<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3 v-if="contact.company_name">{{ `${contact.company_name}` }}</h3>
          <h3 v-else>{{ `${contact.firstname} ${contact.lastname}` }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_CONTACTS)"
            @click="deleteContact"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_CONTACTS)"
            @click="editContact"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="contact?.contactable?.type">
          <dt>
            {{ contactableTypeName }}
          </dt>
          <dd>
            <router-link
              v-if="contact.organization"
              :to="$objectViewRoute(contact.contactable)"
            >
              {{ `${contactableName}` }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.TITLE") }}</dt>
          <dd v-if="contact?.title">
            {{ $t("COMMON." + contact?.title) }}
          </dd>
        </dl>

        <dl class="row" v-if="contact.firstname">
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="text-capitalize">
            {{ contact.firstname }}
          </dd>
        </dl>

        <dl class="row" v-if="contact.lastname">
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="text-uppercase">
            {{ contact.lastname ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ contact.company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ contact.email }}
          </dd>
        </dl>

        <dl class="row" v-if="contact.phone">
          <dt>{{ $t("COMMON.PHONE") }}</dt>
          <dd>
            <phone-number
              :phoneNumber="contact.phone"
              :extension="contact.phone_extension"
              :type="contact.phone_type"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.BIRTHDAY") }}</dt>
          <dd v-if="contact.birthday">
            {{ $formatDate(contact.birthday, "dddd D MMMM YYYY") }}
          </dd>
        </dl>

        <dl class="row" v-if="contact.country">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ contact.country }}
          </dd>
        </dl>

        <dl class="row" v-if="contact.state">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd class="text-uppercase">
            {{ contact.state }}
          </dd>
        </dl>

        <dl class="row" v-if="contact.city">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd class="text-uppercase">
            {{ contact.city }}
          </dd>
        </dl>

        <dl class="row" v-if="contact.zipcode">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ contact.zipcode }}
          </dd>
        </dl>

        <dl class="row" v-if="contact.address">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="text-uppercase">
            {{ contact.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="contact.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="contact.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $formatDate(contact.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $formatDate(contact.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";

export default {
  name: "contact-view-global",

  props: ["contact"],

  components: { Tags },

  data() {
    return {};
  },

  computed: {
    contactableName() {
      if (this.contact.contactable.type == "contacts") {
        return this.contact.contactable.company_name;
      } else if (this.contact.contactable.type == "customers") {
        return this.contact.contactable.customer_name;
      }
      return "N/A";
    },
    contactableTypeName() {
      if (this.contact.contactable.type == "contacts") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.contact.contactable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    contactUpdated() {
      this.$emit("contactUpdated", true);
    },
    editContact() {
      this.$emit("onEditContact", this.contact);
    },
    deleteContact() {
      this.$emit("onDeleteContact", this.contact);
    },
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
